.container-portfolio {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden !important;
  position: relative;

  img {
    position: relative;

    transform: scale(1);
  }

  // img:hover {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // width: 30vw;
  //   transform: scale(1.1);
  //   box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.671);

  //   transition: all 0.5s ease-in-out;
  // }
}
@media (min-width: 768px) {
  .container-portfolio {
    width: 750px;  
  }



}
@media (min-width: 992px) {
  .container-portfolio {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container-portfolio {
    width: 1170px;
  }
}
