.horizontal-nav {
    background: #0b0f18;
    position: fixed;
    z-index: 999 !important;
    top: 0;
    left: 0;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 1rem;
}

.horizontal-nav-logo {
    img {
        max-width: 8rem;
        border-radius: 0 !important;
    }
}

.horizontal-nav-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;

    p {
        margin: 0 1.5rem;
        cursor: pointer;
    }

    @media (max-width: 768px) {
        flex-direction: column;

        p{
            margin: 1.5rem;
        }
    }
}

.horizontal-nav-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    width: auto;
}
