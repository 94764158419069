@import "../../mixins.scss";

.home {
    &-hero {
        background: rgb(219, 217, 217);
        height: 100vh;
        width: 100%;
        align-items: center;
        background-size: cover;
        position: relative;
        // overflow: hidden;
        flex-direction: column;
        overflow-y: hidden;
        z-index: 9;

        video {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            object-fit: cover;
        }

        &-phrase {
            position: absolute;
            top: 40%;
            left: 10%;
            color: white;
            opacity: 1 !important;
            z-index: 99;

            &-name{
                @font-face {
                    font-family: 'Shapiro95SuperExtd';
                    src: local('Shapiro95SuperExtd'), url(../../Shapiro95SuperExtd.otf) format('otf');
                    /* other formats include: 'woff2', 'truetype, 'opentype',
                                              'embedded-opentype', and 'svg' */
                  }
                  font-family: 'Shapiro95SuperExtd' !important;
            }



            @include bp-down(m) {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
            }
        }

        &::after {
            width: 100%;
            height: 100%;
            content: "";
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.4);
        }

        &-right {
            position: absolute;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            align-items: center;
            right: 1rem;
            // background-color: red;
            height: 100%;
            z-index: 3;
        }

        &-left {
            position: absolute;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            align-items: center;
            left: 1rem;
            // background-color: red;
            height: 100%;
            z-index: 3;
        }
    }

    &-profile-photo {
        width: 4rem;
        height: 4rem;
        padding: 0.5rem;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border: 1px solid rgba(255, 255, 255, 0.18);
        margin-right: 0.5rem;
        margin-top: 2rem;
        margin-bottom: 2rem;

        z-index: 2;

        --transition-duration: 500ms;
        --transition-easing: ease-out;

        width: var(--size);
        height: var(--size);

        display: flex;
        align-items: center;
        justify-content: center;

        text-decoration: none;
        color: var(--foreground-color);
        font-size: 2rem;
        border: 1px solid rgba(white, 0.6);
        background-color: rgba(white, 0.1);
        backdrop-filter: blur(2px);
        // border-radius: 50%;
        border-radius: 20px;
        box-shadow: 0px calc(var(--size) / 6) calc(var(--size) / 3) rgba(black, 0.1);

        transition: transform var(--transition-duration) var(--transition-easing);

        overflow: hidden;

        &::before {
            content: "";
            background: rgba(white, 0.4);
            width: 60%;
            height: 100%;
            top: 0%;
            left: -125%;

            transform: skew(45deg);

            position: absolute;

            transition: left var(--transition-duration) var(--transition-easing);
        }

        &::before {
            left: 150%;
            animation: slide-left 3s infinite;
        }

        &:hover {
            transform: scale(1.1);
        }

        img {
            width: 4rem;
            height: 4rem;
            @include bp-down(m) {
                width: 3.5rem;
                height: 3.5rem;
            }
        }
    }

    &-profile-photo:hover {
        cursor: pointer;
    }

    &-socials {
        width: 5rem;
        height: 5rem;
        padding: 0.5rem;

        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border: 1px solid rgba(255, 255, 255, 0.18);
        margin: 0.5rem;
        z-index: 2;

        --transition-duration: 500ms;
        --transition-easing: ease-out;

        margin-bottom: 2rem;

        width: var(--size);
        height: var(--size);

        display: flex;
        align-items: center;
        justify-content: center;

        text-decoration: none;
        color: var(--foreground-color);
        font-size: 2rem;
        border: 1px solid rgba(white, 0.6);
        background-color: rgba(white, 0.1);
        backdrop-filter: blur(2px);
        border-radius: 50%;
        box-shadow: 0px calc(var(--size) / 6) calc(var(--size) / 3) rgba(black, 0.1);

        transition: transform var(--transition-duration) var(--transition-easing);

        overflow: hidden;

        &::before {
            content: "";
            background: rgba(white, 0.4);
            width: 60%;
            height: 100%;
            top: 0%;
            left: -125%;

            transform: skew(45deg);

            position: absolute;

            transition: left var(--transition-duration) var(--transition-easing);
        }

        &::before {
            left: 150%;
            animation: slide-left 3s infinite;
        }

        &:hover {
            transform: scale(1.1);
        }

        img {
            width: 5rem;
            height: 5rem;
        }
    }

    &-socials:hover {
        cursor: pointer;
    }

    &-nft {
        position: fixed;
        z-index: 999;
        top: 7%;
        // background: #0b0f18;
        color: white !important;
        overflow: hidden;
        width: 100%;
        // transform: translateY(0rem);
        h1 {
            font-size: 2rem !important;
        }
        p {
            font-size: 1rem !important;
        }
        .form-minimal {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 2rem;
            color: white !important;

            label {
                color: white;
            }
            input {
                color: white;
            }
            textarea {
                color: white;
            }

            input{
                color: black;
            }

            textarea {
                color: black;
            }
        }
    }
}

.nav {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 5rem;
    right: 0;
}

.hero-sponsors-container {
    // background-color: red;
    position: absolute;
    bottom: 0;
    height: 5rem;
    width: 90%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.hero-sponsors-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    &-logo {
        width: 4rem;
        height: 4rem;
        opacity: 0.8;
    }
}

@keyframes slide-left {
    from {
        left: -100%;
    }
    to {
        left: 150%;
    }
}

.home-about {
    position: relative !important;
}
