.ham-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    // border: 0.2rem solid white;
    &-hamburger {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        height: 80%;
        z-index: 999;

        &__button {
            height: 0.2rem;
            width: 2rem;
            background: white;
            border-radius: 5px;
            transition: all 0.5s ease-in-out;
        }

        &__button::before,
        &__button::after
        {
            content: '';
            position: absolute;
            height: 0.2rem;
            width: 2rem;
            background: white;
            border-radius: 5px;
            transition: all 0.5s ease-in-out;
        }

        &__button::before{
            transform: translateY(-.5rem) translateX(.3rem);
        }

        &__button::after{
            transform: translateY(.5rem) translateX(-.3rem);
        }

        &.open &__button {
            transform: translateX(-3.5rem);
            background: transparent;
        }

        &.open &__button::before {
            transform: rotate(45deg) translateX(0.1rem) translateY(0rem);
        }

        &.open &__button::after {
            transform: rotate(-45deg) translateX(-0.25rem) translateY(0rem);
        }
    }

    &-content {
        position: absolute;
        // right: 0;
        // top: 3rem;
        top: 3rem;
        transform: translateX(30rem);
        width: 50vw;
        height: 100vh;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        background: #0B0F18;
        padding: 4rem .3rem;

        text-transform: uppercase;
        font-weight: bold;
    
        p {
            cursor: pointer;
        }
        transition: all 0.5s ease-in-out;
    }
    &-content.open {
        // right: 0;
        transform: translateX(-3rem);
        top: 3rem;
        transition: all 0.5s ease-in-out;
    }
}
