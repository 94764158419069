$animationSpeed: 30s;

// Animation
@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-100%);
	}
}

// Styling
.slider {
	// background: black;
	// margin: 0rem auto;
	padding-bottom: 1rem;
	overflow: hidden;
	position: absolute;
	bottom: 0rem;
	width: 100%;
	z-index: 99;

	.slide-track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		width: calc(350px * 14);
	}

	.slide {
		width: 350px;
		img {
			// width: 350px;
			object-fit: cover;
			height: 80px;
			margin: auto;
		}
	}
}

/*Filter styles*/
.saturate {
	filter: saturate(3);
}
.grayscale {
	filter: grayscale(100%);
}
.contrast {
	filter: contrast(160%);
}
.brightness {
	filter: brightness(0.25);
}
.blur {
	filter: blur(3px);
}
.invert {
	filter: invert(100%);
}
.sepia {
	filter: sepia(100%);
}
.huerotate {
	filter: hue-rotate(180deg);
}
.rss.opacity {
	filter: opacity(50%);
}
