$color-purple: #ff9f31;
$color-pink: #4b4b4b;

.masonry-minimal-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 130%;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-image: linear-gradient(45deg, $color-purple, $color-pink);
  opacity: 0;
  animation: scroll-vertical 20s linear infinite .1s;
}

.masonry-minimal-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
  padding-top: 2rem;
}

.masonry-minimal-grid_column {
  margin-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.masonry-minimal-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  // background: rgba(255, 255, 255, 0.1);
  // box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.205);
  // backdrop-filter: blur(16px);
  // -webkit-backdrop-filter: blur(6px);
  // border-radius: 10px;
  // border: 1px solid rgba(255, 255, 255, 0.068);
  // margin-bottom: 30px;
  padding: 1rem;
  padding-bottom: 4rem;
  // padding-bottom: 20rem;
  // padding-top: 20rem;
  z-index: 1;
}

/* Optional, different gutter size on mobile */
@media (max-width: 800px) {
  .masonry-grid {
    margin-left: -15px; /* gutter size offset */
  }
  .masonry-grid_column {
    padding-left: 15px; /* gutter size offset */
  }
  .masonry-grid_column > div {
    margin-bottom: 15px; /* space between items */
  }
}

@keyframes scroll-vertical {
  0% {
    top: 10%;
    opacity: 0;
  }
  3% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  98%{
    opacity: 1;
  }
  100% {
    top: -20%;
    opacity: 0;
  } 
}
