@mixin bp-down($size) {
    @if $size == m {
        @media (max-width: 991.98px) {
            @content;
        }
    }
    @if $size == s {
        @media (max-width: 767.98px) {
            @content;
        }
    }
    @if $size == xs {
        @media (max-width: 575.98px) {
            @content;
        }
    }
}
