.contact-us {
  &-description {
    padding: 4rem;

    h1{
      text-transform: uppercase;
    }
  }
}

.form-minimal {
  &-close {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    cursor: pointer;
    color: #0B0F18;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;

  &-submit {
    padding: 1rem;
    border: 1px solid white;
    background: #0B0F18;
    transition: 0.5s ease-in-out;
    text-align: left;
  }

  &-submit:hover {
    // transform: scale(1.03);
    // box-shadow: 0 1px 10px 0 rgba(0, 255, 42, 0.205);
    background: White;
    color: #0B0F18;
    text-align: left;
  }

  label {
    color: #0B0F18;
    margin-bottom: 0.5rem;
  }

  input {
    position: relative;
    color: #0B0F18;
    resize: none;
    width: 90%;
    margin-bottom: 1rem;
  }

  textarea {
    position: relative;
    margin: 1rem;
    width: 90%;
    color: #0B0F18;
    resize: none;
    margin: 0;
    margin-bottom: 1rem;
    padding: 0.5rem;
    height: 10rem;
  }
}
