.footer-minimal {
    // margin-top: 0 !important;
    display: flex;
    flex-direction: column;
    border-top: 5px solid rgb(29, 29, 29);

    &-dejan {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        padding-top: 1rem;

        img {
            width: 2.5rem;
            height: 2.5rem;
            filter: invert(100%);
        }

        &-socials{
            svg{
                margin-left: 1rem;
            }
        }

        a{
            color: #0b0f18;
        }
    }

    &-william {
        padding-top: 0;
        font-size: 1rem !important;
        background: #d98b45;
        width: 100%;
        padding-bottom: 0;
        margin-bottom: 0;
        text-align: center;
    }

    hr {
        background: #d98b45;
        width: 100%;
        padding-top: 1rem;
        margin: 0;
    }
}
