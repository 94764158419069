.about-single-page {
    &-description {
        @media (max-width: 768px) {
            h1 {
                font-size: 2.2rem !important;
            }

            p {
                font-size: 1rem !important;
            }
        }
    }
}
