@import "../../mixins.scss";

.service-modal {
    &::after {
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.596);
        border-radius: 1rem;
    }

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        object-fit: cover;
        border-radius: 1rem;
    }

    img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        object-fit: cover;
        border-radius: 2rem;
    }

    &-wrapper {
        width: 95vw;
        height: 80vh;
        z-index: 9;
        background-color: black;
        position: absolute;
        top: -20%;
        border-radius: 2rem;
        box-shadow: 2px 2px 20px black;
        text-align: center;

        @include bp-down(m) {
            top: 15%;
            width: 95vw;
            height: 80vh;
        }

        h1 {
            position: absolute;
            left: 5%;
            width: 90%;
            top: 5%;
            color: white;
            z-index: 99;

            @include bp-down(m) {
                position: absolute;
                left: 5%;
                top: 15%;
                color: white;
                z-index: 99;
                font-size: 1.4rem !important;
            }
        }

        p {
            position: absolute;
            left: 5%;
            top: 25%;
            width: 90%;
            color: white;
            z-index: 99;
            font-size: 1.4rem !important;

            @include bp-down(m) {
                position: absolute;
                left: 5%;
                top: 25%;
                color: white;
                z-index: 99;
                font-size: 1rem !important;
            }
        }
    }

    &-contact {
        position: absolute;
        // left: 5%;
        bottom: 5%;
        width: 100%;
        color: white;
        z-index: 99;
        .button-black {
            width: 80%;
        }
    }

    &-close {
        position: absolute;
        right: 5%;
        top: 5%;
        color: white;
        z-index: 99;
    }
}

.services-single-page {
    img {
        width: 50%;
    }

    padding-top: 3rem;
    padding-bottom: 3rem;
}
